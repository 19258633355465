/* eslint-env browser */

// This path is replaced by the correct one by rollup-plugin-replace. This is
// done to create modern builds without the polyfill and one including the
// polyfill


import defer from './defered';

const ENV = '<@prod@>';
const VERSION = '<@AUTOTRACKER_VERSION@>';
const MINIFIED = ENV === 'PROD' ? '.min' : '';
const SRC = `//<@SDK_CDN@>/versioned/${VERSION}/pulse${MINIFIED}.js`;

window.pulse =
  window.pulse ||
  function (...rest) {
    const promise = defer(rest);
    (window.pulse.q = window.pulse.q || []).push(promise);
    return promise;
  };

window.pulse.q = window.pulse.q || [];

const script = document.createElement('script');
const b = document.getElementsByTagName('script')[0];
script.async = 1;
script.src = SRC;
b.parentNode.insertBefore(script, b);
